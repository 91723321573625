.control-panel {
    /* position: absolute; */
    top: 3.1rem;
    right: 0;
    background: #fff;
    max-width: 1.25rem;
}
.color-code {
    border: 1px double;
    border-radius: 50%;
    display: inline-block;
    height: 15px;
    margin-right: 5px;
    width: 15px;
}

.layer-list-wrapper {
    /* max-height:calc(90vh - 245px); */
    max-height: calc(100vh - 360px);
     overflow: auto !important;
     padding-right: 0.8rem;
}
/* @media screen and (max-height:768px){
    .layer-list-wrapper {
        max-height: 55vh;
    }
} */

.layer-list-wrapper::-webkit-scrollbar {
    width:9px;
    height: 9px;
}

.layer-list-wrapper::-webkit-scrollbar-thumb {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(0,0,0,0.2);
}

.layer-list-wrapper::-webkit-scrollbar-thumb:hover {
    background:rgba(0,0,0,0.4);
}

.layer-list-wrapper::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.2);
}

.myCheckBox {
    transform : scale(1);
    }

.custom-select{
    padding: 2px;
    width: 90px;
    border: 1px solid #CCC;
    background-color: inherit;
    font-size: 11px;
    text-align-last: center;
}
.select-text{
    font-size: 10px;
    font-weight:600;
    display: block;
    
}
    
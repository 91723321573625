.control {
  position: absolute;
  bottom: 100px;
  left: 0px;
  background-color: white;
  opacity: 0.5;
  max-width: 320px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
.fa,
.far,
.fas {
  font-size: 1rem;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  /* background-color: transparent; */
  border: unset;
  padding: 5px;
  box-shadow: none;
}

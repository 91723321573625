html, body{
    font-family: 'Lato','Roboto', 'Helvetica', 'Arial', sans-serif;
}
@keyframes loader {
    from {
        left: 0vw
    }
    to{
        left: 51vw
    }
}

h3{
    animation: loader 5s infinite
}

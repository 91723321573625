.mapboxgl-ctrl.mapboxgl-ctrl button:not(:disabled):hover {
    background-color:#95c11f;
}

.mapboxgl-ctrl-group button:focus:only-child{
    border-radius: unset;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon{
    cursor:pointer;
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group button+button  {
    border:none
}

.mapboxgl-ctrl-group.mapboxgl-ctrl-group  {
    background:transparent;
    display: flex; 
}

.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: unset;
    -webkit-box-shadow: unset !important;
    box-sizing: unset !important;
}
.step-wrapper img, svg{
    vertical-align: middle;
}

.step-wrapper > div{
    display: flex;
    align-items: center;
}

.step-wrapper > div > p{
    margin-left: 10px;
}
.step-wrapper{
    max-height: 40vh;
    overflow: auto;
}
.fa, .far, .fas{
    font-size: 1rem;
  
  }
.mapboxgl-ctrl-geocoder{
    box-shadow: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-bottom: 1px solid;
    border-radius: 0;
    background-color: rgba(255,255,255, 0.8);
    font-size: inherit !important;
}

.mapboxgl-ctrl-geocoder--input:focus, .mapboxgl-ctrl-geocoder--input:active{
    border-bottom: 2px solid;
    outline: none
}
.mapboxgl-ctrl-geocoder--button{
    background: none;
}

@media only screen and (max-width: 600px){
    .mapboxgl-ctrl-geocoder{
        min-width: unset;
        width: 6.8rem;
    }
    .mapboxgl-ctrl-geocoder--input{
        height: 2.5rem;
        padding: unset;
        padding-left: 0.7rem;
        padding-right: 1.5rem;
        font-size: 80%;
    }
    .mapboxgl-ctrl-geocoder--icon-search{
        display: none;
    }
    .mapboxgl-ctrl-geocoder--icon-close{
        margin-right: 0.3rem;

    }
    .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > *{
        top: 0.3rem;
        right: 0;
    }
    .mapboxgl-ctrl-geocoder--icon-close{
        width: 0.9rem;
        height: 0.9rem;
    }
}

html,body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    /* overflow-y: hidden; */
    
}

#map-container {
    position: relative;
    width: 100vw;
    height: calc(100vh - 180px);
    top: 0px;
    left: 0px;
    
}

.mapboxgl-ctrl-scale.mapboxgl-ctrl-scale{
    background-color: rgba(255,255,255,0.8);
    font-size: 10px;
    /* border: 2px solid #333; */
    /* border-top: #333; */
    /* padding: 0 5px; */
    color: #333;
   border: 0px;
}
.mapboxgl-popup-content{
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #434343;
    border-radius:5px;
    z-index: 100000;
    background: rgba(255,255,255, 0.8);
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip{
    border-bottom-color: #ffff;  
  }

.popup-list-wrapper{
    max-height: 12rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}
.popup-listed{
    margin: 5px;
}


.popup-list-wrapper::-webkit-scrollbar {
    width:0.5rem;
}

.popup-list-wrapper::-webkit-scrollbar-thumb {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(0,0,0,0.2);
}

.popup-list-wrapper::-webkit-scrollbar-thumb:hover {
    background:rgba(0,0,0,0.4);
}

.popup-list-wrapper::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.2);
}

.popup-header{
    font-style: italic;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    width: auto;
}


.mapboxgl-popup-close-button{
    font-size: 1.2em;
}
.mapboxgl-popup-content{
    user-select: text;
    cursor:auto
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E") ;
}

.mapboxgl-ctrl-scale.mapboxgl-ctrl-scale{
    font-size:0.8rem; 
}
